import {
  HomePage,
  JobPage,
  GalleryPage,
  ProductPage,
  OperatorPage,
  PurchasePage,
  PreorderPage,
} from "pages";
import {
  GALLERY,
  JOBS,
  PRODUCTS,
  ROOT,
  NOTFOUND,
  OPERATOR,
  PURCHASE,
  LEGAL,
  PREORDER,
  SUBSCRIBE,
  VENDOR_APPLICARTION,
} from "constants";
import { createBrowserRouter } from "react-router-dom";
import { AppLayout } from "layouts/AppLayout";
import { ProductsLayout } from "layouts/ProductsLayout";
import PrivacyPolicy from "pages/legal/PrivacyPolicy";
import TermsAndConditions from "pages/legal/TermsAndConditions";
import Snipper from "pages/snipper/Snipper";
import TermsAndConditionsPurchase from "components/TermsAndConditions";
import TermsAndConditionsVendor from "components/TermsAndConditionsVendor";
import SubscribePage from "pages/form/SubscribePage";
import VendorApplication from "pages/form/VendorApplication";

export const router = createBrowserRouter([
  {
    path: ROOT,
    element: <AppLayout />,
    children: [
      {
        path: ROOT,
        element: <HomePage />,
      },
      {
        path: JOBS,
        element: <JobPage />,
      },
      {
        path: GALLERY,
        element: <GalleryPage />,
      },
      {
        path: PRODUCTS,
        element: <ProductsLayout />,
      },
      {
        path: `${PRODUCTS}/:slug`,
        element: <ProductPage />,
      },
      {
        path: OPERATOR,
        element: <OperatorPage />,
      },
      {
        path: PURCHASE,
        element: <PurchasePage />,
      },
      {
        path: VENDOR_APPLICARTION,
        element: <VendorApplication />,
      },
      {
        path: PREORDER,
        element: <PreorderPage />,
      },
      {
        path: SUBSCRIBE,
        element: <SubscribePage />,
      },
      {
        path: LEGAL.PRIVACY_POLICY,
        element: <PrivacyPolicy />,
      },
      {
        path: LEGAL.TERMS_AND_CONDITIONS,
        element: <TermsAndConditions />,
      },
      {
        path: "/terms-and-conditions-purchase",
        element: <TermsAndConditionsPurchase />,
      },
      {
        path: "/terms-and-conditions-vendor",
        element: <TermsAndConditionsVendor />,
      },
      {
        path: NOTFOUND,
        element: <HomePage />,
      },
      {
        path: "/snipper",
        element: <Snipper />,
      },
    ],
  },
]);
