import { useEffect } from "react";
import Form from "../Form";
import { validate } from "utils";

const inputs = [
  {
    label:
      "What challenges are you facing in running your business? (Check all that apply)",
    check: true,
    name: "challenges",
    options: [
      {
        label: "Lack of affordable infrastructure (e.g., kitchens, locations)",
        points: 10,
      },
      {
        label: "Difficulty obtaining permits and licenses",
      },
      {
        label: "Struggles with food costing and pricing",
        points: 5,
      },
      {
        label: "Limited access to quality raw materials",
      },
      {
        label: "Inadequate delivery and ordering platforms",
      },
    ],
  },
  {
    label:
      "Do you face difficulties finding high-demand locations to set up your business?",
    select: true,
    name: "difficulties",
    required: true,
    options: [
      {
        label: "--Difficulties--",
      },
      {
        label: "Yes",
        value: "Yes",
      },
      {
        label: "No",
        value: "No",
      },
    ],
  },
  {
    label: "How do you currently calculate your food costs?",
    select: true,
    name: "costMethod",
    required: true,
    options: [
      {
        label: "--Cost Method--",
      },
      {
        label: "Manually",
        value: "Manually",
      },
      {
        label: "Using software",
        value: "Using software",
      },
      {
        label: "I don’t calculate food costs",
        value: "I don’t calculate food costs",
      },
    ],
  },
];

function Challenges({ name, formData, setNextDisabled, updateData }) {
  useEffect(() => {
    validate(formData, name, setNextDisabled, inputs);

    // eslint-disable-next-line
  }, [formData]);
  return (
    <div>
      <Form name={name} onChange={updateData} inputs={inputs} />
    </div>
  );
}

export default Challenges;
