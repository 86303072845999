import { toast } from "react-toastify";
import useAPI from "./useAPI";
import urls from "api/urls";

const useContact = () => {
  const { post, isLoading, errorMessage, successMessage } = useAPI();

  const subscribe = async (details) => {
    const data = await post(urls.news.baseUrl, details);
    if (!data) return;
    toast.success(data.message);
  };

  const contact = async (details) => {
    const data = await post(urls.contact.baseUrl, details);
    if (!data) return;
    toast.success(data.message);
  };

  const submitVendorApplication = async (details, cb) => {
    const data = await post(
      urls.stagingServer + urls.applications.vendor,
      details
    );
    if (data && typeof cb === "function") cb();
  };

  return {
    isLoading,
    subscribe,
    contact,
    errorMessage,
    successMessage,
    submitVendorApplication,
  };
};

export default useContact;
