import { useEffect, useRef, useState } from "react";
import "./Form.scss";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Country } from "country-state-city";
import useBusiness from "hooks/api/useBusiness";
import { numberFormatter } from "utils";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const inputs = [
  {
    label: "First Name",
    placeholder: "Enter your first name",
    type: "text",
    name: "firstName",
  },
  {
    label: "Last Name",
    placeholder: "Enter your last name",
    type: "text",
    name: "lastName",
  },
  {
    label: "Email",
    placeholder: "username@email.com",
    type: "email",
    name: "email",
  },
  {
    label: "Phone number",
    phone: true,
    name: "phoneNumber",
  },
  {
    label: "Country",
    select: true,
    name: "country",
  },

  {
    label: "City",
    city: true,
    select: true,
    name: "city",
  },
];

// eslint-disable-next-line
export default () => {
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [phoneValue, setPhoneValue] = useState(null);
  const [countries, setCountries] = useState([]);
  const [selectedCity, setCity] = useState("abuja");
  const [payUrl, setPayUrl] = useState(null);

  const formRef = useRef();

  const {
    isLoading,
    vendor,
    getPreorderRate,
    rates,
    getCities,
    cities,
    errorMessage,
  } = useBusiness();

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };
  const handleChangeCity = (e) => {
    setCity(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!phoneValue) return toast.warn("Enter a valid phone number");
    if (!phoneValue) return toast.warn("Select a management type");
    const formData = new FormData(e.target);
    const payload = {
      firstName: formData.get("firstName"),
      lastName: formData.get("lastName"),
      email: formData.get("email"),
      city: selectedCity,
      phoneNumber: phoneValue,
      country: selectedCountry,
    };
    await vendor(payload, (url) => {
      formRef.current.reset();
      setSelectedCountry(null);
      setPhoneValue(null);
      setPayUrl(url);
      window.open(url);
    });
  };

  useEffect(() => {
    const countryData = Object.values(Country.getAllCountries());
    setCountries(countryData);
    getPreorderRate(true);
    getCities();
    setSelectedCountry(countryData[160].name);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (errorMessage) alert(errorMessage);
  }, [errorMessage]);

  return (
    <div className="form_container">
      {payUrl ? (
        <a href={payUrl}>
          <button>Complete Payment</button>
        </a>
      ) : (
        <>
          <div className="form_container-header">
            <h2>Secure your spot in iCart today!</h2>
            <p>
              With a refundable {numberFormatter(rates ? rates.vendor : 0)}{" "}
              deposit. Fill and send the file below and we will get in touch
              with you.
            </p>
          </div>
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="form_inputs">
              {inputs.map((value, _id) => (
                <div key={_id} className="form_control">
                  <label>{value.label}</label>
                  {value.phone ? (
                    <PhoneInput
                      className="input_holder"
                      placeholder="+234 801 234 5678"
                      defaultCountry="NG"
                      value={phoneValue}
                      onChange={(e) => setPhoneValue(e)}
                    />
                  ) : value.select && countries.length > 0 ? (
                    <select
                      className="input_holder"
                      value={
                        (value.city ? selectedCity : selectedCountry) || ""
                      }
                      onChange={
                        value.city ? handleChangeCity : handleCountryChange
                      }
                    >
                      {(value.city
                        ? cities.filter((_) => _.isActive)
                        : countries
                      )?.map((country, _id) => (
                        <option key={_id} value={country.value || country.name}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div className="input_holder">
                      <input
                        name={value.name}
                        required
                        type={value.type}
                        placeholder={value.placeholder}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="accept">
              <input
                onClick={() => setAcceptTerms(!acceptTerms)}
                type="checkbox"
              />
              <span>
                you've read and accepeted our purchase{" "}
                <Link className="terms-link" to="/terms-and-conditions-vendor">
                  terms and condtions
                </Link>
              </span>
            </div>
            <button
              className="disabled-btn"
              disabled={isLoading || !acceptTerms}
            >
              {isLoading ? "Loading..." : "Send"}
            </button>
          </form>
        </>
      )}
    </div>
  );
};
