import { useEffect } from "react";
import Form from "../Form";
import { validate } from "utils";

const inputs = [
  {
    label: "What is your age?",
    select: true,
    name: "age",
    required: true,
    options: [
      {
        label: "--Age--",
      },
      {
        label: "Under 18",
        value: "u18",
      },
      {
        label: "18–24",
        value: "18–24",
      },
      {
        label: "25–34",
        value: "25–34",
      },
      {
        label: "35–44",
        value: "35–44",
      },
      {
        label: "45+",
        value: "45+",
      },
    ],
  },
  {
    label: "What is your gender?",
    select: true,
    name: "gender",
    required: true,
    options: [
      {
        label: "--Gender--",
      },
      {
        label: "Male",
        value: "male",
      },
      {
        label: "Female",
        value: "female",
      },
      {
        label: "Prefer not to say",
        value: "unknown",
      },
    ],
  },
  {
    label: "Country",
    select: true,
    name: "country",
    required: true,
  },
  {
    label: "City",
    name: "city",
    placeholder: "City",
    required: true,
  },
  {
    label: "What is your employment status?",
    name: "employment",
    placeholder: "Employment Status",
    required: true,
    select: true,
    options: [
      {
        label: "--Employment Status--",
      },
      {
        label: "Self Employed",
        value: "Self Employed",
      },
      {
        label: "Working",
        value: "Working",
      },
      {
        label: "Unemployed",
        value: "Unemployed",
      },
    ],
  },
  {
    label: "What is your highest level of education?",
    name: "education",
    placeholder: "Education",
    required: true,
    select: true,
    options: [
      {
        label: "--Education--",
      },
      {
        label: "Primary School",
        value: "Primary School",
      },
      {
        label: "Secondry School",
        value: "Secondry School",
      },
      {
        label: "College/University",
        value: "College/University",
      },
      {
        label: "Vocational Training",
        value: "Vocational Training",
      },
      {
        label: "None",
        value: "None",
      },
    ],
  },
];

function Demographics({ name, formData, setNextDisabled, updateData }) {
  useEffect(() => {
    validate(formData, name, setNextDisabled, inputs);
    // eslint-disable-next-line
  }, [formData]);

  return (
    <div>
      <Form name={name} inputs={inputs} onChange={updateData} />
      {/* <div className="form_container">
        <form>
          <div className="form_inputs">
            <div className="form_control">
              <label>What is your age?</label>
              <select className="input_holder">
                <option>Age</option>
                <option value="u18">Under 18</option>
                <option value="18-24">18-24</option>
                <option value="25–34">25–34</option>
                <option value="35–44">35–44</option>
                <option value="45+">45+</option>
              </select>
            </div>
            <div className="form_control">
              <label>What is your gender?</label>
              <select className="input_holder">
                <option>Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="unknown">Prefer not to say</option>
              </select>
            </div>
          </div>
        </form>
      </div> */}
    </div>
  );
}

export default Demographics;
