import { useEffect } from "react";
import Form from "../Form";
import { validate } from "utils";

const inputs = [
  {
    label:
      "Would you like us to contact you to discuss how we can help your business?",
    select: true,
    name: "shouldContact",
    required: true,
    options: [
      {
        label: "--Should we Contact--",
      },
      {
        label: "Yes",
        value: "Yes",
      },
      {
        label: "No",
        value: "No",
      },
    ],
  },
  {
    dependant: "shouldContact",
    target: "Yes",
    name: "contactEmail",
    label: "Email",
    placeholder: "Email",
    required: true,
  },
  {
    dependant: "shouldContact",
    target: "Yes",
    name: "contactPhone",
    label: "Phone Number",
    placeholder: "Phone Number",
    required: true,
  },
];

function Contact({ name, formData, setNextDisabled, updateData }) {
  useEffect(() => {
    validate(formData, name, setNextDisabled, inputs);

    // eslint-disable-next-line
  }, [formData]);
  return (
    <div>
      <Form name={name} onChange={updateData} inputs={inputs} />
    </div>
  );
}

export default Contact;
