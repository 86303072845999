const urls = {
  // baseUrl: "http://192.168.89.156:1818/api",
  baseUrl: "https://live-api-icart.onrender.com/api",
  // stagingServer: "http://192.168.1.124:1818",
  stagingServer: "https://staging-api-icart.onrender.com",
  // baseUrl: "http://localhost:1818/api",
  business: {
    plans: "/v1/business/subscriptions/plans",
    subscribe: "/v1/business/subscriptions/subscribe",
    preorder: "/v1/business/subscriptions/preorder",
    vendor: "/v1/business/subscriptions/vendor",
    preorderRate: "/v1/business/subscriptions/preorder-rate",
    concepts: "/v1/business/concepts",
  },
  applications: {
    vendor: "/api/v2/applications",
  },
  auth: {
    signup: "/v1/auth/signup",
  },
  contact: {
    baseUrl: "/v1/contact",
  },
  news: {
    baseUrl: "/v1/news/signup",
  },
  files: {
    baseUrl: "/v1/files/",
  },
  operations: {
    cities: "/v1/operations/cities",
  },
};

// test

export default urls;
