import { useEffect } from "react";
import Form from "../Form";
import { validate } from "utils";

const inputs = [
  {
    label: "First Name",
    placeholder: "Enter your first name",
    type: "text",
    name: "firstName",
    required: true,
  },
  {
    label: "Last Name",
    placeholder: "Enter your last name",
    type: "text",
    name: "lastName",
    required: true,
  },
  {
    label: "Email",
    placeholder: "username@email.com",
    type: "email",
    name: "email",
    required: true,
  },
  {
    label: "Phone number",
    phone: true,
    name: "phoneNumber",
    required: true,
  },
];

function PersonalInfo({ name, formData, setNextDisabled, updateData }) {
  useEffect(() => {
    validate(formData, name, setNextDisabled, inputs);
    // eslint-disable-next-line
  }, [formData]);

  return (
    <div>
      <Form onChange={updateData} inputs={inputs} name={name} />
      {/* <div className="form_container">
        <form>
          <div className="form_inputs">
            <div className="form_control">
              <label>First Name</label>
              <div className="input_holder">
                <input
                  name="firstName"
                  required
                  type="text"
                  placeholder="First Name"
                />
              </div>
            </div>
            <div className="form_control">
              <label>Last Name</label>
              <div className="input_holder">
                <input
                  name="lastName"
                  required
                  type="text"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="form_control">
              <label>Email</label>
              <div className="input_holder">
                <input name="email" required type="email" placeholder="Email" />
              </div>
            </div>
            <div className="form_control">
              <label>Phone Number</label>
              <div className="input_holder">
                <input
                  name="phoneNumber"
                  required
                  type="phoneNumber"
                  placeholder="Phone Number"
                />
              </div>
            </div>
          </div>
        </form>
      </div> */}
    </div>
  );
}

export default PersonalInfo;
