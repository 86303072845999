import { useEffect } from "react";
import Form from "../Form";
import { validate } from "utils";

const inputs = [
  {
    label: "What are your business goal for the next year?",
    select: true,
    name: "goal",
    required: true,
    options: [
      {
        label: "--Select Goal--",
      },
      {
        label: "Increase sales",
        value: "Increase sales",
      },
      {
        label: "Expand product offerings",
        value: "Expand product offerings",
        points: 5,
      },
      {
        label: "Open a physical location",
        value: "Open a physical location",
      },
      {
        label: "Improve operational efficiency",
        value: "Improve operational efficiency",
        points: 5,
      },
      {
        label: "Other",
        value: "Other",
      },
    ],
  },
  {
    name: "goalAlt",
    dependant: "goal",
    target: "Other",
    placeholder: "Goal",
    label: "Business Goal",
    required: true,
  },
  {
    label:
      "If you could improve one thing about your business today, what would it be?",
    textarea: true,
    name: "improvement",
    required: true,
  },
  {
    label:
      "Would you be interested in a platform that provides the following? (Check all that apply)",
    check: true,
    name: "platformInterest",
    options: [
      {
        label: "Licensed kiosk locations",
        points: 5,
      },
      {
        label: "Digital food ordering and delivery system",
      },
      {
        label: "Tools to calculate food costs (like CostDish)",
        points: 5,
      },
      {
        label: "AI insights on high-demand locations",
      },
      {
        label: "Affordable and reliable raw material supply",
      },
    ],
  },
];

function Need({ name, formData, setNextDisabled, updateData }) {
  useEffect(() => {
    validate(formData, name, setNextDisabled, inputs);

    // eslint-disable-next-line
  }, [formData]);
  return (
    <div>
      <Form name={name} onChange={updateData} inputs={inputs} />
    </div>
  );
}

export default Need;
