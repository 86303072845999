import React from "react";
import "./About.scss";
import { whoWeAre, whoWeAre2 } from "assets";
import { abouts } from "constants";
import { services } from "constants";

export const About = () => {
  return (
    <div className="about_container" id="about">
      <section>
        <div className="about_card-1">
          <div className="card-1">
            <div className="card-1_top">
              <h2>Who we are.</h2>
              <p className="justify">
                At iCart Africa, we are revolutionizing the Quick Service
                Restaurant (QSR) industry by empowering micro-entrepreneurs with
                cutting-edge infrastructure and technology. Our smart
                solar-powered kiosks provide a reliable and sustainable solution
                for food businesses, overcoming challenges like high setup costs
                and unreliable electricity.
              </p>
              <p className="justify">
                Integrated with a digital platform for food delivery services, a
                dynamic advertisement network, and select EV fast-charging
                outlets, we are building a future where food businesses thrive,
                customers enjoy convenience, and communities benefit from
                innovation.
              </p>
            </div>
            <div className="about_card-img">
              <img src={whoWeAre2} alt="Who we are" />
            </div>
          </div>
          <div className="card-2">
            {abouts.map((about, uid) => (
              <div key={uid}>
                <div className="card_2-icon">
                  <img src={about.icon} alt={about.title} />
                </div>
                <h3>
                  <span>{about.title[0]}</span>
                  {about.title[1]}
                </h3>
                <p className="justify">{about.content}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="about_card-2">
          <div className="content_header">
            <h2>What we do</h2>
            <p>
              We offer a range of services all in the bid to make business easy
              for you.
            </p>
          </div>

          <div className="what_we_do">
            {services.map((service, uid) => (
              <div key={uid} className="what_we_do-card">
                <div className="what_we_do-card_top">
                  <div>
                    <img src={service.icon} alt="icon" />
                  </div>
                  <h3>{service.title}</h3>
                </div>
                <p className="justify">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};
