import useQuery from "hooks/useQuery";
import "../../css/vendor.css";
import Demographics from "./questionaire/Demographics";
import PersonalInfo from "./questionaire/PersonalInfo";
import { useEffect, useState } from "react";
import { VENDOR_APPLICARTION } from "constants";
import Business from "./questionaire/Bussiness";
import { useNavigate } from "react-router-dom";
import Challenges from "./questionaire/Challenges";
import Digital from "./questionaire/Digital";
import Contact from "./questionaire/Contact";
import Need from "./questionaire/Need";
import store from "utils/store";
import { transformData } from "utils";
import useContact from "hooks/api/useContact";
import { toast } from "react-toastify";

const storeKey = "VendorSpaceFormData";

const tabs = [
  {
    name: "Personal Information",
    note: "Fill in your personal information below.",
    Element: PersonalInfo,
  },
  {
    name: "Demographics",
    note: "Fill in your demographic information below.",
    Element: Demographics,
  },
  {
    name: "Business Profile",
    note: "Fill in your business information below.",
    Element: Business,
  },
  {
    name: "Challenges",
    note: "Business Chanllenges and Solutions",
    Element: Challenges,
  },
  {
    name: "Digital and Sustainability Preferences",
    note: "Subit a Digital and Sustainability Preferences",
    Element: Digital,
  },
  {
    name: "Needs and Aspirations",
    note: "Enter your business needs and aspirations.",
    Element: Need,
  },
  {
    name: "Contact Information (Optional)",
    note: "Provide a contact information for us to reach out to you.",
    Element: Contact,
  },
  //   {
  //     name: "Review & Submit",
  //     note: "Review Your Application and Sbumit",
  //     Element: ReviewAndSubmit,
  //   },
];

function VendorApplication(props) {
  const [currentTab, setCurrentTab] = useState(0);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [formData, setFormData] = useState({});
  const query = useQuery();
  const { name, Element, note } = tabs[currentTab];
  const { isLoading, submitVendorApplication } = useContact();
  const tab = query.get("tab");
  const navigate = useNavigate();

  const navigatePrev = () =>
    navigate(
      `${VENDOR_APPLICARTION}?tab=${tabs[Math.max(0, currentTab - 1)].name}`
    );

  const navigateNext = () => {
    if (currentTab >= tabs.length - 1) {
      if (Object.values(formData).length !== tabs.length)
        return navigate(`${VENDOR_APPLICARTION}?tab=${tabs[0]?.name}`);
      const { result, error } = transformData(
        "Vendor Space",
        "vendor",
        formData
      );
      if (error) return toast.warn(error);
      submitVendorApplication(result, () => {
        store.removeAll();
        navigate(`${VENDOR_APPLICARTION}?submitted=true`);
      });
      //  ;
    } else navigate(`${VENDOR_APPLICARTION}?tab=${tabs[currentTab + 1]?.name}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (tab) setCurrentTab(tabs.findIndex((_) => _.name === tab));
    else setCurrentTab(0);
  }, [tab]);

  useEffect(() => {
    if (Object.keys(formData)) store.setItem(storeKey, formData);
  }, [formData]);

  if (query.get("submitted"))
    return (
      <section className="vendor-container">
        <h4 className="text-brown">
          Thank you for submitting your application!!
        </h4>
        <br />

        <p>
          We have received your information and will be reviewing it shortly.
          Our team will get in touch with you via email for further
          communication.
          <br />
          Best regards,
          <br />
          The iCart Team
        </p>

        <br />
        <button onClick={() => navigate("/")}>Back to Home</button>
      </section>
    );

  return (
    <>
      <section className="vendor-container">
        <div className="status-track">
          <div
            style={{ width: `${((currentTab + 1) / tabs.length) * 100}%` }}
          ></div>
        </div>
        <div className="questionaire">
          <h3>
            {currentTab + 1}. {name}
          </h3>
          <p>{note}</p>
          <br />
          {
            <Element
              name={name}
              formData={formData}
              updateData={setFormData}
              setNextDisabled={setNextDisabled}
            />
          }
        </div>
        <br />
        <br />
        <div className="next-prev">
          <button
            onClick={navigatePrev}
            disabled={currentTab <= 0 || isLoading}
          >
            Previous
          </button>

          <button disabled={isLoading || nextDisabled} onClick={navigateNext}>
            {isLoading
              ? "Loading..."
              : currentTab >= tabs.length - 1
              ? "Submit"
              : "Next"}
          </button>
        </div>
      </section>
    </>
  );
}

export default VendorApplication;
