export const currencyFormatter = (n) =>
  n?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const capitalizeFirstLtter = (s) => {
  if (!s) return "";
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const numberFormatter = (n, pre = "₦") =>
  pre + n?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const validate = (formData, name, setNextDisabled, inputs) => {
  if (!formData[name]) return setNextDisabled(true);
  for (let i = 0; i < inputs.length; i++) {
    const input = inputs[i];
    if (input.required) {
      console.log(input.name, {
        dependate: input.dependant,
        dependantValue: formData[name][input.dependant],
        target: input.target,
        answer: formData[name][input.name]?.answer,
        visible: formData[name][input.dependant]?.answer === input.target,
        formData,
      });

      if (
        input.dependant &&
        formData[name][input.dependant]?.answer !== input.target
        // !formData[name][input.name]?.answer
      )
        continue;
      // return setNextDisabled(true);
      if (!formData[name][input.name]?.answer) return setNextDisabled(true);
    }
  }
  setNextDisabled(false);
};

export function transformData(name, role, data) {
  // Initialize the transformed result
  if (
    !data["Personal Information"].email?.answer ||
    !data["Personal Information"].firstName?.answer ||
    !data["Personal Information"].phoneNumber?.answer ||
    !data["Personal Information"].lastName?.answer
  )
    return { error: "Personal information is required" };
  const result = {
    name,
    customerInformation: {
      email: data["Personal Information"].email.answer,
      firstName: data["Personal Information"].firstName.answer,
      lastName: data["Personal Information"].lastName.answer,
      role,
      phoneNumber: data["Personal Information"].phoneNumber.answer,
    },
    response: [],
  };

  // Process each section in the data
  for (const section in data) {
    for (const questionKey in data[section]) {
      const question = data[section][questionKey];

      // Handle answers that are arrays or objects
      let answer;
      if (Array.isArray(question.answer)) {
        answer = question.answer.join(", "); // Convert array to comma-separated string
      } else if (
        typeof question.answer === "object" &&
        question.answer !== null
      ) {
        answer = Object.entries(question.answer)
          .map(([key, value]) => `${key}:${value}`)
          .join(", "); // Convert object to key:value pairs
      } else {
        answer = question.answer; // Just use the string if it's not an array or object
      }

      // Push the transformed question and answer to the response array
      result.response.push({
        question: question.question,
        answer: answer,
        score: question.score || 0,
      });
    }
  }

  return { result };
}
