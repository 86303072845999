import { useEffect } from "react";
import Form from "../Form";
import { validate } from "utils";

const inputs = [
  {
    label: "Are you currently using a food delivery platform?",
    select: true,
    name: "useDeliveryPlatform",
    required: true,
    options: [
      {
        label: "--Using Delivery Platform--",
      },
      {
        label: "Yes",
        value: "Yes",
      },
      {
        label: "No",
        value: "No",
      },
    ],
  },
  {
    label: "Would you be interested in operating from a solar-powered kiosk?",
    select: true,
    name: "solarInterest",
    required: true,
    options: [
      {
        label: "--Solar Interest--",
      },
      {
        label: "Yes",
        value: "Yes",
        points: 10,
      },
      {
        label: "No",
        value: "No",
      },
    ],
  },
  {
    label: "How important is sustainability in your business decisions?",
    select: true,
    name: "sustainability",
    required: true,
    options: [
      {
        label: "--Sustainability Importance--",
      },
      {
        label: "Very Important",
        value: "Very Important",
      },
      {
        label: "Somewhat Important",
        value: "Somewhat Important",
      },
      {
        label: "Not Important",
        value: "Not Important",
      },
    ],
  },
  {
    label:
      "Do you feel comfortable using technology to manage your business (e.g., apps,software)?",
    select: true,
    name: "softwareComfortability",
    required: true,
    options: [
      {
        label: "--Comfortable with Software--",
      },
      {
        label: "Yes",
        value: "Yes",
        points: 10,
      },
      {
        label: "No",
        value: "No",
      },
    ],
  },
];

function Digital({ name, formData, setNextDisabled, updateData }) {
  useEffect(() => {
    validate(formData, name, setNextDisabled, inputs);
    // eslint-disable-next-line
  }, [formData]);
  return (
    <div>
      <Form name={name} onChange={updateData} inputs={inputs} />
    </div>
  );
}

export default Digital;
