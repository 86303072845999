/* 

**Terms and Conditions for iCart Vendors**

1. **Security Deposit Breakdown:**

   - **Refundable Payment:** A refundable amount of 2,000,000 Naira will be provided upon the vendor’s exit after the annual contract, contingent on the iCart being returned in good condition and without any damage.
   - **Non-refundable Training Fee:** A fee of 500,000 Naira, allocated for vendor training, is non-refundable.

2. **iCart Delivery Timeline:**

   - The iCart kiosk will be delivered within 30 days following payment confirmation.

3. **Food Concept Approval:**

   - All food concepts are subject to review and approval by iCart. In the event a concept is rejected, the vendor may reapply with revised concepts until one is deemed suitable and aligns with iCart’s standards.
   - Vendors are permitted to operate only the food concept approved by iCart.

4. **Revenue Sharing:**

   - iCart retains a 25% commission on all items sold through the kiosk, applicable to both online and offline transactions.

5. **Sales Integrity:**

   - Vendors must accurately record all sales in the iCart system. Any attempt to manipulate or alter sales records is strictly prohibited.

6. **Operational Requirements:**

   - Vendors are required to operate the iCart a minimum of six (6) days per week.
   - Vendors must sell at least 35 items per day; failure to meet this target may result in replacement by another vendor.
   - Proper maintenance of the iCart must be ensured by adhering to the provided cleaning and operational manual.
   - Vendors must participate in an online training program lasting 14 days, covering concept creation and uploading.

7. **Prohibited Actions:**

   - Vendors are strictly prohibited from:
     - Applying personal branding to the iCart.
     - Operating the iCart with more than two (2) individuals inside at any given time.
     - Relocating the iCart without prior authorization from iCart management.
     - Performing unauthorized repairs on the iCart.
     - Neglecting to report malfunctions or damages to the iCart.

8. **Penalties for Violations:**

   - Non-compliance with any of the stipulated regulations may result in immediate termination of the contract and eviction from the iCart premises.
   - In cases of contract violation, the security deposit will be forfeited.
   - Vendors found to have intentionally damaged the iCart will be held liable for its full replacement value and may face legal proceedings.

9. **Emergency Reporting:**

   - Vendors are required to report emergencies such as fire, theft, or severe weather conditions to iCart immediately.

10. **Machinery Use:**

   - Vendors may repair machinery they own, provided it has been pre-approved by iCart before being brought into the iCart.

11. **Exit Conditions:**

   - The refundable portion of the security deposit (2,000,000 Naira) will only be returned if the iCart is free of damage and all terms of this agreement have been satisfied at the time of the vendor’s exit.
   - Vendors must provide a written notice of two (2) months prior to their exit; failure to do so may result in forfeiture of the security deposit.

12. **Contract Renewal:**

   - This agreement is subject to annual renewal, contingent on mutual agreement between the vendor and iCart.

By signing this agreement, the vendor acknowledges and agrees to adhere to all terms and conditions outlined herein.

*/

function TermsAndConditionsVendor(props) {
  return (
    <div className="container privacy-policy purchase-terms">
      <h2>Terms and Conditions for iCart Vendors</h2>

      <h2>1. Security Deposit Breakdown:</h2>
      <ul>
        <li>
          <strong>Refundable Payment:</strong> A refundable amount of 500,000
          Naira will be provided upon the vendor’s exit after the annual
          contract, contingent on the iCart being returned in good condition and
          without any damage.
        </li>
        <li>
          <strong>Non-refundable Training Fee:</strong> A fee of 150,000 Naira,
          allocated for vendor training, is non-refundable.
        </li>
      </ul>

      <h2>2. iCart Delivery Timeline:</h2>
      <ul>
        <li>
          All iCart deployment will start in June 2025. Vendors are expected to
          start gaining access to iCart beginning in June 2025. If iCart fails
          to deliver the kiosk by the aforementioned date, iCart will
          communicate a new delivery date to vendors. If a vendor is not
          comfortable with the revised delivery date, they may request a refund
          of the full security deposit only; the training fee will remain
          non-refundable.
        </li>
      </ul>

      <h2>3. Food Concept Approval:</h2>
      <ul>
        <li>
          All food concepts are subject to review and approval by iCart. In the
          event a concept is rejected, the vendor may reapply with revised
          concepts until one is deemed suitable and aligns with iCart’s
          standards.
        </li>
        <li>
          Vendors are permitted to operate only the food concept approved by
          iCart.
        </li>
      </ul>

      <h2>4. Revenue Sharing:</h2>
      <ul>
        <li>
          iCart retains a 25% commission on all items sold through the kiosk,
          applicable to both online and offline transactions.
        </li>
      </ul>

      <h2>5. Sales Integrity:</h2>
      <ul>
        <li>
          Vendors must accurately record all sales in the iCart system. Any
          attempt to manipulate or alter sales records is strictly prohibited.
        </li>
      </ul>

      <h2>6. Operational Requirements:</h2>
      <ul>
        <li>
          Vendors are required to operate the iCart a minimum of six (6) days
          per week.
        </li>
        <li>
          Vendors must sell at least 35 items per day; failure to meet this
          target may result in replacement by another vendor.
        </li>
        <li>
          Proper maintenance of the iCart must be ensured by adhering to the
          provided cleaning and operational manual.
        </li>
        <li>
          Vendors must participate in an online training program lasting 14
          days, covering concept creation and uploading.
        </li>
      </ul>

      <h2>7. Prohibited Actions:</h2>
      <ul>
        <li>
          Vendors are strictly prohibited from:
          <ul>
            <li>Applying personal branding to the iCart.</li>
            <li>
              Operating the iCart with more than two (2) individuals inside at
              any given time.
            </li>
            <li>
              Relocating the iCart without prior authorization from iCart
              management.
            </li>
            <li>Performing unauthorized repairs on the iCart.</li>
            <li>Neglecting to report malfunctions or damages to the iCart.</li>
            <li>Bringing any machinery into the iCart.</li>
          </ul>
        </li>
      </ul>

      <h2>8. Penalties for Violations:</h2>
      <ul>
        <li>
          Non-compliance with any of the stipulated regulations may result in
          immediate termination of the contract and eviction from the iCart
          premises.
        </li>
        <li>
          In cases of contract violation, the security deposit will be
          forfeited.
        </li>
        <li>
          Vendors found to have intentionally damaged the iCart will be held
          liable for its full replacement value and may face legal proceedings.
        </li>
      </ul>

      <h2>9. Emergency Reporting:</h2>
      <ul>
        <li>
          Vendors are required to report emergencies such as fire, theft, or
          severe weather conditions to iCart immediately.
        </li>
      </ul>

      <h2>10. Exit Conditions:</h2>
      <ul>
        <li>
          The refundable portion of the security deposit (500,000 Naira) will
          only be returned if the iCart is free of damage and all terms of this
          agreement have been satisfied at the time of the vendor’s exit.
        </li>
        <li>
          Vendors must provide a written notice of two (2) months prior to their
          exit; failure to do so may result in forfeiture of the security
          deposit.
        </li>
      </ul>

      <h2>11. Contract Renewal:</h2>
      <ul>
        <li>
          This agreement is subject to annual renewal, contingent on mutual
          agreement between the vendor and iCart.
        </li>
      </ul>

      <p>
        By signing this agreement, the vendor acknowledges and agrees to adhere
        to all terms and conditions outlined herein.
      </p>
    </div>
  );
}

export default TermsAndConditionsVendor;
