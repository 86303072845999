import { useEffect } from "react";
import Form from "../Form";
import { validate } from "utils";

const inputs = [
  {
    label: "Are you currently running a food or beverage business?",
    select: true,
    name: "ownBusiness",
    required: true,
    options: [
      {
        label: "--Have a running bussiness--",
      },
      {
        label: "Yes",
        value: "Yes",
        points: 10,
      },
      {
        label: "No",
        value: "No",
        points: 0,
      },
    ],
  },
  {
    label: "How do you currently operate your business?",
    select: true,
    name: "operation",
    dependant: "ownBusiness",
    target: "Yes",
    required: true,
    options: [
      {
        label: "--Bussiness Operation--",
      },
      {
        label: "From home",
        value: "Home",
        points: 0,
      },
      {
        label: "From a rented space",
        value: "Rent",
        points: 0,
      },
      {
        label: "Online Only",
        value: "Online",
        points: 0,
      },
      {
        label: "Other",
        value: "Other",
      },
    ],
  },
  {
    name: "operationAlt",
    dependant: "operation",
    target: "Other",
    placeholder: "How do you currently operate your business?",
    label: "Business Operation",
    required: true,
  },
  {
    label: "Are you actively selling products online or offline",
    select: true,
    name: "activelySelling",
    dependant: "ownBusiness",
    target: "Yes",
    required: true,
    options: [
      {
        label: "--Are you actively selling--",
      },
      {
        label: "Yes",
        value: "Yes",
        points: 10,
      },
      {
        label: "No",
        value: "No",
        points: 10,
      },
    ],
  },

  {
    label: "What types of food or beverages do you offer?",
    select: true,
    name: "beverages",
    dependant: "ownBusiness",
    target: "Yes",
    required: true,
    options: [
      {
        label: "--Serving Beverages--",
      },
      {
        label: "Local Cuisine",
        value: "Local Cuisine",
      },
      {
        label: "Snacks and Drinks",
        value: "Snacks and Drinks",
      },
      {
        label: "Specialty Meals (vegan, fusion, etc.)",
        value: "Specialty Meals",
      },
      {
        label: "Other",
        value: "Other",
      },
    ],
  },
  {
    name: "beveragesAlt",
    dependant: "beverages",
    target: "Other",
    placeholder: "What types of food or beverages do you offer?",
    label: "What types of food or beverages do you offer?",
    required: true,
  },
  {
    label: "How long have you been in business?",
    select: true,
    name: "businessDuration",
    dependant: "ownBusiness",
    target: "Yes",
    required: true,
    options: [
      {
        label: "--Business Duration--",
      },
      {
        label: "Less than 1 year",
        value: "Less than 1 year",
        points: 2,
      },
      {
        label: "1–3 years",
        value: "1-3 yeas",
        points: 5,
      },
      {
        label: "3+ years",
        value: "3+ years",
        points: 10,
      },
    ],
  },
  {
    label: "How do you currently sell your products? (Check all that apply)",
    check: true,
    name: "salesMethods",
    dependant: "ownBusiness",
    target: "Yes",
    pointsFor: {
      count: 2,
      points: 5,
    },
    options: [
      {
        label: "In person",
      },
      {
        label: "Social media platforms",
      },
      {
        label: "Food delivery apps",
      },
    ],
  },
];

function Business({ name, formData, setNextDisabled, updateData }) {
  useEffect(() => {
    validate(formData, name, setNextDisabled, inputs);

    // eslint-disable-next-line
  }, [formData]);

  return (
    <div>
      <Form name={name} onChange={updateData} inputs={inputs} />
    </div>
  );
}

export default Business;
