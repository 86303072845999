import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Country } from "country-state-city";
import { useEffect, useState } from "react";
import store from "utils/store";

function Form({ inputs, onChange, name, storeKey = `Form:${name}` }) {
  const [values, setValues] = useState(
    name ? store.getItem(storeKey) || {} : {}
  );
  // eslint-disable-next-line
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    const countryData = Object.values(Country.getAllCountries());
    setCountries(countryData);
    setSelectedCountry(countryData[160].name);
  }, []);

  useEffect(() => {
    if (Object.keys(values) && name) store.setItem(storeKey, values);
    if (typeof onChange === "function")
      onChange((formData) => ({ ...formData, [name]: values }));
    // eslint-disable-next-line
  }, [values, name, storeKey]);

  return (
    <div className="form_container">
      {/* <p>{JSON.stringify(values)}</p> */}
      <form>
        <div className="form_inputs">
          {inputs
            .filter((_) =>
              _.dependant ? values[_.dependant]?.answer === _.target : true
            )
            .map((value, _id) => (
              <div key={_id} className="form_control">
                <label>{value.label}</label>
                {value.check ? (
                  value.options.map((_, idx) => (
                    <div key={idx} className="check-container">
                      <input
                        onChange={(e) => {
                          let extrapoints = 0;
                          const items = values[value.name]?.answer
                            ? [
                                ...values[value.name]?.answer.filter(
                                  (__) => __ !== _.label
                                ),
                              ]
                            : [];
                          if (e.target.checked) {
                            items.push(_.label);
                          }
                          if (
                            value.pointsFor &&
                            items.length >= value.pointsFor.count
                          ) {
                            extrapoints = value.pointsFor.points;
                          }
                          setValues((values) => ({
                            ...values,
                            [value.name]: {
                              question: value.question || value.label,
                              score:
                                (items
                                  .map(
                                    (i) =>
                                      value.options.find((o) => o.label === i)
                                        .points || 0
                                  )
                                  .reduce((p, c) => p + c, 0) || 0) +
                                extrapoints,
                              answer: items,
                            },
                          }));
                        }}
                        checked={values[value.name]?.answer?.includes(_.label)}
                        type="checkbox"
                      />
                      <p>{_.label}</p>
                    </div>
                  ))
                ) : value.phone ? (
                  <PhoneInput
                    className="input_holder"
                    placeholder="+234 801 234 5678"
                    defaultCountry="NG"
                    value={values[value.name]?.answer}
                    onChange={(e) => {
                      setValues((values) => ({
                        ...values,
                        [value.name]: {
                          question: value.question || value.label,
                          score: value.points || 0,
                          answer: e,
                        },
                      }));
                    }}
                  />
                ) : value.select && countries.length > 0 ? (
                  <select
                    name={value.name}
                    value={values[value.name]?.answer}
                    onChange={(e) => {
                      if (e.target.value && !e.target.value.startsWith("--"))
                        setValues((values) => ({
                          ...values,
                          [value.name]: {
                            question: value.question || value.label,
                            answer: e.target.value,
                            score:
                              (value.options || countries).find(
                                (o) =>
                                  o.value === e.target.value ||
                                  o.name === e.target.value
                              ).points || 0,
                          },
                        }));
                      else
                        setValues((values) => ({
                          ...values,
                          [value.name]: undefined,
                        }));
                    }}
                    className="input_holder"
                  >
                    {(value.options || countries)?.map((item, _id) => (
                      <option
                        // disabled={!item.value & !item.name}
                        key={_id}
                        value={item.value || item.name}
                        // value={JSON.stringify({
                        //   value: item.value || item.name,
                        //   points: item.points,
                        // })}
                      >
                        {item.label || item.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <div className="input_holder">
                    <input
                      onChange={(e) => {
                        setValues((values) => ({
                          ...values,
                          [value.name]: {
                            question: value.question || value.label,
                            answer: e.target.value,
                          },
                        }));
                      }}
                      name={value.name}
                      value={values[value.name]?.answer}
                      required={value.required}
                      type={value.type}
                      placeholder={value.placeholder}
                    />
                  </div>
                )}
              </div>
            ))}
        </div>
      </form>
    </div>
  );
}

export default Form;
